import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheAppContainer = () => import('@/containers/TheAppContainer')

//Sections
const EnrollmentForm = () => import('@/pages/web/EnrollmentForm')
const DynamicForm = () => import('@/pages/web/DynamicForm')

const Login = () => import('@/pages/application/login/Login')
const ForgetForm = () => import('@/pages/application/login/ForgetForm')
const RecoveryForm = () => import('@/pages/application/login/RecoveryForm')

const Banks = () => import('@/pages/application/Banks')
const Schools = () => import('@/pages/application/Schools')
const Travels = () => import('@/pages/application/Travels')
const Travel = () => import('@/pages/application/Travel')
const Student = () => import('@/pages/application/Student')

const Payments = () => import('@/pages/application/Payments')

const Questions = () => import('@/pages/application/Questions')

const Users = () => import('@/pages/application/Users')
const Roles = () => import('@/pages/application/Roles')
const Admins = () => import('@/pages/application/Admins')

// Views
const Home = () => import('@/pages/application/Home')
//externo
const StudentsExternal = () => import('@/pages/application/StudentsExternal')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/travel/:code/form',
      name: 'forms',
      meta: {
        is_public: true,
      },
      component: EnrollmentForm
    },
    {
      path: '/travel/:code/xform',
      name: 'forms',
      meta: {
        is_public: true,
      },
      component: DynamicForm
    },
    {
      path: '/',
      name: 'welcome',
      redirect: "/travels",
      component: TheContainer,
      children: [
        {
          path: '/schools',
          name: 'Escuelas',
          component: Schools,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/accounts',
          name: 'Cuentas Bancarias',
          component: Banks,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/travels',
          name: 'Viajes',
          component: Travels,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/travels/:id/show',
          name: 'Ficha de Viaje',
          component: Travel,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/students/:id/show',
          name: 'Ficha de Alumno',
          component: Student,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/users',
          name: 'Usuarios',
          component: Users,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/admins',
          name: 'Administradores',
          component: Admins,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/roles',
          name: 'Roles',
          component: Roles,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/questions',
          name: 'Preguntas',
          component: Questions,
          meta: {
            is_admin: true,
          },
        },
        {
          path: '/payments',
          name: 'Pagos Recibidos',
          component: Payments,
          meta: {
            is_admin: true,
          },
        }
      ]
    },
    {
      path: '/app',
      redirect: "/home",
      component: TheAppContainer,
      children: [
        {
          path: '/home',
          name: 'Inicio',
          component: Home
        }
      ]
    },
    {
      path: '/student/external/:code',
      name: 'StudentExternal',
      meta: {
        is_public: true,
      },
      component: StudentsExternal
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/forget',
      name: '¿Olvidaste tu Contraseña?',
      meta: {
        is_public: true,
      },
      component: ForgetForm
    },
    {
      path: '/account/recover-password/:id/:code',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: RecoveryForm
    }
  ]
})